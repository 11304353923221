<template>
  <section class="w-full h-full absolute welcome-explorer__pages">
    <div
      class="welcome-explorer__pages--gray"
      :style="{ '--height': mobile ? 'min(40%, 300px)' : '75%' }"
    >
      <SvgLogo
        :logo="researchLogos[0]"
        :svg-width="tenantLogoWidth"
        alt="explorer icon"
        right
        :class="researchLocaleKeyRoot === 'research-cl' ? 'mt-8 mr-4' : ''"
        class="welcome-explorer__pages--logo"
      />
      <div :class="mobile ? '' : 'd-flex'">
        <ImageAtom
          class="welcome-explorer__pages--image"
          :class="{
            'welcome-explorer__pages--image--desktop': !mobile,
            'welcome-explorer__pages--image--mobile': mobile,
          }"
          :source="require(`@/assets/information/${pageInformation.image}`)"
          :alt-text="'next'"
        />
        <div
          :class="mobile ? '' : 'text-left welcome-explorer__pages--text'"
        >
          <TextAtom
            :value="pageInformation.title"
            :color="'primary-blue'"
            font="poppins"
            weight="600"
            size="medium"
            :class="mobile ? 'm-1' : 'mt-6'"
          />
          <TextAtom
            :value="pageInformation.subtitle"
            :color="'neutral-800'"
            font="inter"
            weight="300"
            :size="mobile ? 'subtitle' : 'big'"
            :class="mobile ? 'mt-5 align-center' : 'welcome-explorer__pages--subtitle'"
          />
        </div>
      </div>
    </div>
    <div :class="mobile ? 'welcome-explorer__button--mobile' : 'welcome-explorer__button'">
      <ModalContent
        v-if="pageInformation.step !== 6 && researchLocaleKeyRoot !== 'research-cl'"
        :class="!mobile ? 'welcome-explorer__skip' : 'welcome-explorer__skip--mobile'"
        :content-sections="['MultiStyleText']"
        :multi-text-array="skipArray"
        @underlineClick="skip()"
      />
    </div>
    <div
      class="register-main"
      :class="!mobile ? 'welcome-explorer__continue' : 'welcome-explorer__continue--mobile'"
    >
      <NextButtonResearch @click="increaseStep()" />
    </div>
  </section>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import NextButtonResearch from '@/components/atoms/buttons/NextButtonResearch.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'WelcomePages',
  components: {
    SvgLogo,
    TextAtom,
    ImageAtom,
    ModalContent,
    NextButtonResearch,
  },
  props: {
    pageInformation: {
      type: Object,
      default: null,
    },
    researchLocaleKeyRoot: {
      type: String,
      default: null,
    },
    researchLogos: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      skipArray: [
        {
          text: this.$t('start_explorer.new_flow.skip'),
          underline: [1],
          bold: [1],
        },
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tenantLogoWidth() {
      if (this.researchLocaleKeyRoot === 'research-do') {
        return '50px';
      }
      return '100px';
    },
  },
  methods: {
    increaseStep() {
      this.$emit('increaseStep');
    },
    skip() {
      this.$emit('skip');
    },
  },
};
</script>
