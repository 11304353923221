<template>
  <div>
    <ModalHeader
      v-if="false"
      :content-sections="['actions']"
      :title-text="'views.login.password-recovery.title'"
      inline-logo
      class="welcome-explorer__register--back"
      @back="$emit('backStep')"
    />
    <SvgLogo
      :logo="researchLogos[0]"
      svg-width="50"
      alt="explorer icon"
      right
      class="welcome-explorer__logo"
    />
    <div
      :class="mobile ? 'ml-10' : 'ml-15'"
    >
      <SvgLogo
        v-if="researchLogos.length > 1"
        :logo="researchLogos[1]"
        svg-width="150"
        left
        alt="explorer icon"
        class="welcome-explorer__register--logo"
      />
      <ImageAtom
        class="deg-waiting-list--line mt-4"
        :source="require('@/assets/waitingList/dge-line.svg')"
        :alt-text="'dge-line'"
      />
      <TextAtom
        :value="(
          $te(`preexist_login.confirm_user.${researchLocaleKeyRoot}_${researchData.intervention_label?.id}`)
            ? $t(`preexist_login.confirm_user.${researchLocaleKeyRoot}_${researchData.intervention_label?.id}`)
            : $t(`preexist_login.confirm_user.${researchLocaleKeyRoot}`)
        )"
        :color="'primary-dark'"
        font="poppins"
        weight="400"
        :size="mobile ? 'subtitle' : 'big'"
        class="mb-7 mt-7 d-flex text-left welcome-explorer--confirm"
      />
    </div>
    <div
      class="register-main"
      :class="mobile ? 'welcome-explorer__continue--mobile' : 'welcome-explorer__continue'"
    >
      <NextButtonResearch @click="$emit('nextStep')" />
    </div>
  </div>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import NextButtonResearch from '@/components/atoms/buttons/NextButtonResearch.vue';

export default {
  name: 'PreexistentUserConfirmation',
  components: {
    SvgLogo,
    TextAtom,
    ImageAtom,
    ModalHeader,
    NextButtonResearch,
  },
  props: {
    researchLocaleKeyRoot: {
      type: String,
      default: null,
    },
    researchData: {
      type: Object,
      default: () => ({}),
    },
    researchLogos: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
