<template>
  <div>
    <ModalHeader
      :content-sections="['actions']"
      :title-text="'views.login.password-recovery.title'"
      inline-logo
      class="welcome-explorer__register--back"
      @back="$emit('backStep')"
    />
    <SvgLogo
      :logo="'only-explorer.svg'"
      svg-width="50"
      alt="explorer icon"
      right
      class="welcome-explorer__logo"
    />

    <div
      :class="mobile ? 'ml-10' : 'ml-15'"
    >
      <template v-if="!isTenantDominicana">
        <TextAtom
          :value="$t('start_explorer.pre_created.account_title')"
          :color="'primary-dark'"
          font="poppins"
          weight="500"
          size="big"
          class="mb-7 d-flex"
        />
        <SvgLogo
          :logo="'inline-tether-logo.svg'"
          svg-width="250"
          left
          alt="explorer icon"
          class="welcome-explorer__register--logo"
        />
      </template>
      <SvgLogo
        v-else
        :logo="'logo-dom.svg'"
        svg-width="200"
        left
        alt="Gobierno de la república dominicana"
      />
      <ImageAtom
        class="deg-waiting-list--line mt-4"
        :source="require('@/assets/waitingList/dge-line.svg')"
        :alt-text="'dge-line'"
      />
      <ModalContent
        class="welcome-explorer__register--password"
        :style="mobile ? ' width: 87% !important;' : ' width: 30% !important;'"
        :content-sections="['subtitle', 'registerInput', 'passValidation']"
        :subtitle-text="'start_explorer.pre_created.password_title'"
        :input-section="inputContent"
        @setValue="setValue"
      />
    </div>
    <CallToActions
      :class="mobile ? 'welcome-explorer__continue--mobile' : 'welcome-explorer__continue'"
      :main-button-text="'digital_enrollment.waiting-list.confirmation.button'"
      :types="['main']"
      medium
      :loader="resetLoading"
      :spaced="false"
      :active-value="isActive"
      option-btn-no-margin
      @continue="changePassword"
    />
  </div>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { isValidEmail } from '@/utils/strings';
import { mapActions, mapGetters } from 'vuex';
import CONFIG from '@/config';

export default {
  name: 'PreexistentUserPassword',
  components: {
    CallToActions,
    ModalContent,
    SvgLogo,
    TextAtom,
    ImageAtom,
    ModalHeader,
  },
  data() {
    return {
      inputContent: [
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          inputType: 'password',
          password: 0,
          show: false,
        },
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          inputType: 'password',
          password: 1,
          show: true,
        },
      ],
      password: '',
      noInfoError: false,
      resetLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      resetPasswordStatus: 'authentication/resetPasswordStatus',
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
      userEmail: 'authentication/userEmail',
      userPhone: 'authentication/userPhone',
      usernameCheck: 'authentication/usernameCheck',
    }),
    isTenantDominicana() {
      return CONFIG.tenant === 'dom';
    },
    isActive() {
      if (this.password) {
        return true;
      }
      return false;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    currentHeadMasterMainSchool: {
      handler(newVal) {
        if (newVal) {
          this.resetLoading = false;
          this.$router.push('/dashboard');
        }
      },
    },
  },
  methods: {
    ...mapActions({
      success: 'utils/success',
      error: 'utils/error',
      tokenResetPassword: 'authentication/tokenResetPassword',
      doLogin: 'authentication/doLogin',
    }),
    changePassword() {
      if (!this.password) {
        this.noInfoError = true;
        return this.noInfoError;
      }
      this.resetLoading = true;
      this.tokenResetPassword({
        password1: this.password,
        password2: this.password,
        callback: () => {
          this.raiseSnackbar();
        },
        legalGuardian: true,
      });
      return null;
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 1) {
        this.password = mainValue;
      }
      this.inputContent[index].value = mainValue;
      this.inputContent[index].noInfoError = false;
    },
    raiseSnackbar() {
      if (this.resetPasswordStatus === 'OK') {
        this.doLogin({
          username: this.usernameCheck,
          email: isValidEmail(this.userEmail) ? this.userEmail : '',
          password: this.password,
          phone: this.userPhone,
        })
          .then(() => {
            this.success(this.$t('views.reset_password.password_changed'));
            return this.$emit('nextStep');
          });
      } else {
        this.error(this.$t('views.reset_password.password_not_changed'));
      }
    },
  },
};
</script>
