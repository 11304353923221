<template>
  <div v-if="fetching" class="w-full h-full d-flex justify-center align-center">
    <LoadingSpinner
      show
      :size="80"
      :width="8"
    />
  </div>
  <section v-else>
    <div v-if="step.content === 'welcome'" class="w-full h-full absolute explorer__start__new">
      <picture class="d-flex flex-row-reverse justify-space-between w-full mb-5 mt-3 p-10">
        <ImageAtom
          v-for="(logo, index) in researchLogos"
          :key="index"
          :src="logo.startsWith('http') ? logo : require(`@/assets/logos/${logo}`)"
          object-fit="contain"
          :width="index === 0 ? '60' : '70'"
        />
      </picture>
      <div
        class="welcome-explorer"
        :style="{ '--marginLeft': mobile ? '45px' : '80px' }"
      >
        <TextAtom
          :value="getWelcomePath('subtitle')"
          tag="h2"
          color="primary-dark"
          font="poppins"
          weight="500"
          size="big"
          class="mb-2"
        />
        <TextAtom
          :value="getWelcomePath('title')"
          :color="tenantColorTitle"
          font="poppins"
          weight="600"
          size="large"
          tag="h1"
          class="mt-2"
        />
        <TextAtom
          :value="getWelcomePath('second_title')"
          :color="tenantColorTitle"
          font="poppins"
          weight="600"
          size="large"
          tag="h1"
          class="mt-6 underline-text customize-underline-research"
        />
        <TextAtom
          :value="getWelcomePath('description')"
          tag="p"
          :color="'primary-dark'"
          font="inter"
          weight="400"
          :size="mobile ? 'subtitle' : 'big'"
          class="mt-14 welcome-explorer__text"
          :style="{ '--width': mobile ? '80%' : '70%' }"
        />
      </div>
      <div
        class="register-main"
        :class="mobile ? 'welcome-explorer__continue--mobile' : 'welcome-explorer__continue'"
      >
        <NextButtonResearch @click="increaseStep()" />
      </div>
    </div>

    <WelcomePages
      v-if="step.content === 'information_pages'"
      :page-information="pagesInformation[page]"
      :research-locale-key-root="researchLocaleKeyRoot"
      :research-logos="researchLogos"
      @increaseStep="increaseStepPages()"
      @skip="skip()"
    />
    <PreexistentUserLoginFlow
      v-if="step.content === 'terms_and_conditions' && !isOrganicResearch"
      :research-locale-key-root="researchLocaleKeyRoot"
      :research-data="researchData"
      :research-logos="researchLogos"
    />
    <!--
      FIXME: Organic research flow is completely broken
      <OrganicUserLoginFlow
        v-if="step.content === 'flow_location' && isOrganicResearch"
        :research-locale-key-root="researchLocaleKeyRoot"
      />
    -->
    <BaseSnackbar />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import WelcomePages from '@/components/molecules/welcome_pages/WelcomePages.vue';
import LoadingSpinner from '@/components/atoms/loaders/LoaderCircular.vue';

// import OrganicUserLoginFlow from '@/components/templates/OrganicUserLoginFlow.vue';
import PreexistentUserLoginFlow from '@/components/templates/PreexistentUserLoginFlow.vue';
import BaseSnackbar from '@/components/explorer/base/BaseSnackbar.vue';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import NextButtonResearch from '@/components/atoms/buttons/NextButtonResearch.vue';
// eslint-disable-next-line import/no-cycle
import trackMixPanel from '@/utils/mixpanel';
import reportsService from '@/services/reports.service';

export default {
  name: 'ResearchFlow',
  components: {
    TextAtom,
    WelcomePages,
    PreexistentUserLoginFlow,
    // OrganicUserLoginFlow,
    BaseSnackbar,
    ImageAtom,
    NextButtonResearch,
    LoadingSpinner,
  },
  data() {
    return {
      fetching: true,
      step: {
        index: 0,
        content: '',
      },
      steps: [],
      page: 0,
      researchLocaleKeyRoot: `research-${TENANT_CONFIGURATION.DEFAULTS.LOCALE.split('-')[1].toLowerCase()}`,
      researchData: {
        id: null,
        uuid: null,
        intervention_label: {},
        survey: {
          uuid: null,
          content: {},
          answers: {},
          submitted: false,
        },
        user: null,
        applicant_uuid: null,
        legalguardian_uuid: null,
        treatment: null,
        organic: true,
        guest: true,
      },
      pagesInformation: [
        {
          title: this.$t('start_explorer.new_flow.pages.page1.title'),
          subtitle: this.$t('start_explorer.new_flow.pages.page1.subtitle'),
          image: 'new-map-phone.webp',
          step: 1,
        },
        {
          title: this.$t('start_explorer.new_flow.pages.page2.title'),
          subtitle: this.$t('start_explorer.new_flow.pages.page2.subtitle'),
          image: 'travel-times-phone.webp',
          step: 2,
        },
        {
          title: this.$t('start_explorer.new_flow.pages.page3.title'),
          subtitle: this.$t('start_explorer.new_flow.pages.page3.subtitle'),
          image: 'audiovisual-phone.webp',
          step: 3,
        },
        {
          title: this.$t('start_explorer.new_flow.pages.page4.title'),
          subtitle: this.$t('start_explorer.new_flow.pages.page4.subtitle'),
          image: 'testimonials-phone.webp',
          step: 4,
        },
        {
          title: this.$t('start_explorer.new_flow.pages.page5.title'),
          subtitle: this.$t('start_explorer.new_flow.pages.page5.subtitle'),
          image: 'simulation-phone.webp',
          step: 5,
        },
        {
          title: this.$t('start_explorer.new_flow.pages.page6.title'),
          subtitle: this.$t('start_explorer.new_flow.pages.page6.subtitle'),
          image: 'general-explorer-phone.webp',
          step: 6,
        },
      ],
    };
  },
  computed: {
    isOrganicResearch() {
      return this.researchData.organic;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tenantColorTitle() {
      return this.researchLocaleKeyRoot.includes('cl') ? 'primary-blue' : 'primary-600';
    },
    researchLogos() {
      const { intervention_label: { id: interventionLabelId, networks } } = this.researchData;
      if (networks?.length) {
        return networks.map(({ network_logo: logo }) => logo);
      }
      const isChileWhiteLabel = this.researchLocaleKeyRoot.includes('cl') && interventionLabelId !== 8;
      const isDominicana = this.researchLocaleKeyRoot.includes('dom');
      if (isChileWhiteLabel) {
        return ['cl/mineduc.svg', 'cl/sae.svg'];
      }
      if (isDominicana) {
        return ['only-explorer.svg', 'logo-dom.svg'];
      }
      return ['only-explorer.svg'];
    },
  },
  watch: {
    isOrganicResearch: {
      handler() {
        this.setSteps();
      },
      immediate: true,
    },
  },
  mounted() {
    const { researchId } = this.$route.params;
    this.initializeResearch(researchId);
    if (window.Intercom) window.Intercom('update', { hide_default_launcher: true });
  },
  methods: {
    ...mapActions({
      getUserMainInfo: 'authentication/getUserMainInfo',
      setPreCreatedUser: 'authentication/setPreCreatedUser',
      snackbar: 'utils/info',
      doLoginFeedback: 'authentication/doLoginFeedback',
    }),
    initializeResearch(researchId) {
      this.fetching = true;
      const dataFetcher = (
        researchId
          ? reportsService.getResearchInformation
          : reportsService.generateResearch
      );
      dataFetcher({ researchId }).then(({ data }) => {
        const {
          user: userUUID,
          applicants,
        } = data;

        // TODO: Handle case of multiple applicants, so that the user can choose one
        const [studentUUID] = applicants;

        this.researchData = data;

        if (researchId && userUUID) {
          this.doLoginFeedback({ uuid: userUUID, studentUUID });
          this.getUserMainInfo({ uuid: userUUID });
        } else {
          this.setPreCreatedUser({ preCreatedUser: false });
        }

        if (data?.survey?.submitted) {
          this.snackbar(this.$t('snackbar.research_completed'));
          this.$router.push({ name: 'Explorer' });
        }
      }).finally(() => {
        this.fetching = false;
      });
    },
    getWelcomePath(suffix) {
      const { intervention_label: { id: interventionLabelId } } = this.researchData;
      if (this.$te(`${this.researchLocaleKeyRoot}_${interventionLabelId}.welcome.${suffix}`)) {
        return `${this.researchLocaleKeyRoot}_${interventionLabelId}.welcome.${suffix}`;
      }
      return `${this.researchLocaleKeyRoot}.welcome.${suffix}`;
    },

    setSteps() {
      const typeOfUser = this.isOrganicResearch ? 'organic' : 'preexisting';
      const steps = TENANT_CONFIGURATION.SETTINGS.RESEARCH.STEP[typeOfUser];
      this.steps = Object.values(steps);
      this.step = {
        index: 0,
        content: this.steps[0],
      };
    },
    increaseStepPages() {
      if (this.page < 5 && this.researchLocaleKeyRoot === 'research-do') {
        this.page += 1;
      } else {
        this.increaseStep();
      }
    },
    increaseStep() {
      this.step = {
        index: this.step.index + 1,
        content: this.steps[this.step.index + 1],
      };
      trackMixPanel(`click_bienvenida_${this.step.index - 1}_continue`);
    },
    skip() {
      this.page = this.pagesInformation.length - 1;
    },
  },
};
</script>
<style>
.customize-color {
  color:  #62C7D3;
}

.customize-underline-research .underline-research {
  display: inline-block;
  letter-spacing: 0.1px;
}

.customize-underline-research .underline-research::after {
  content: '';
  height: 12px;
  background: #62C7D3;
  border-radius: 5px;
  display: block;
  max-width: 260px;
  margin-top: 10px;
}
</style>
