<template>
  <div>
    <UserConsent
      :logos="researchLogos"
      :consent-codes="researchData?.intervention_label.consents.length > 0
        ? researchData.intervention_label.consents
        : undefined"
      :title="preCreated ? $t(`${researchLocaleKeyRoot}.welcome.precreated`)
        : $t('start_explorer.pre_created.normal_account_subtitle')"
      full-screen
      @submitted="$emit('nextStep')"
      @rejected="skip"
    />
  </div>
</template>

<script>
import UserConsent from '@/components/organisms/general/UserConsent.vue';

export default {
  name: 'PreexistentUserWelcome',
  components: {
    UserConsent,
  },
  props: {
    preCreated: {
      type: Boolean,
      default: false,
    },
    researchLocaleKeyRoot: {
      type: String,
      default: null,
    },
    researchLogos: {
      type: Array,
      default: () => ([]),
    },
    researchData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    skip() {
      this.$emit('skip');
      this.$router.push('/map');
    },
  },
};
</script>
